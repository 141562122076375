import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "react-bootstrap/Button"
import ViewMore from "../images/vMore.png"
import CloseButtonImg from "../images/close-48.png"
import A from "../images/a1000.png"
import B from "../images/b1000.png"
import CC from "../images/cb1000.png"
import sideImageL from "../images/siderailimg.png"
import sideImageR from "../images/siderailimg.png"
import "../components/static/styles/mainEvents.css"
import "../components/static/styles/imageHolder.css"

const divStyles = {
  borderRadius: `0.5rem`,
  display: `grid`,
  gridTemplateColumns: `1fr`,
  gridTemplateRows: `auto`,
  width: `100%`,
  // maxWidth: `320px`,
}

const eventStyles = {
  display: `grid`,
  gridTemplateColumns: `1fr`,
  gridTemplateRows: `auto`,
  textAlign: `left`,
  padding: `0.3rem`,
  borderBottom: `2px solid #4CAF50`,
  color: `black`,
  fontSize: `0.9rem`,
  lineHeight: `2rem`,
  margin: `0.8rem auto 0 auto`,
}

const imgButtonStyles = {
  width: `2.2rem`,
  height: `auto`,
}

class Events extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      arr: [
        {
          id: 1,
          startDate: "Jun 28 2020",
          endDate: "Dec 23 2020",
          bookingStartTime: "18:00",
          bookingEndTime: "19:00",
          topic:
            "Yoga With Annie ~ Hatha Yoga Flow. Posture driven focussing on the mind-body connection",
          person: "Annie D York",
          prodCode: "sku_FkjyqVPNE4TaIC",
        },
        {
          id: 2,
          startDate: "July 12 2020",
          endDate: "Dec 24 2020",
          bookingStartTime: "19:00",
          bookingEndTime: "20:00",
          topic: "Learn to Meditate with Lorea & Pascal",
          person: "Lorea Rainstorm & Pascal Truth",
          prodCode: "sku_FkjyqVPNE4TaIC",
        },
        {
          id: 3,
          startDate: "Sep 12 2020",
          endDate: "Dec 28 2020",
          bookingStartTime: "18:00",
          bookingEndTime: "20:00",
          topic:
            "Advanced Yoga Flow Series - Hatha And Ashtanga with choreography",
          person: "Emilia Rowe-Wasseur",
          prodCode: "sku_FkjyqVPNE4TaIC",
        },
      ],
      prodCode: "",
      display0: false,
      display1: false,
      display2: false,
    }
  }

  logMessages(number) {
    const arrIndex = number - 1

    switch (number) {
      case 1:
        this.setState({
          prodCode: "sku_FkjyqVPNE4TaIC",
        })
        console.log("Booking for", this.state.arr[arrIndex].person)
        break
      case 2:
        this.setState({
          prodCode: "sku_FkjyqVPNE4TaIC",
        })
        console.log("Booking for", this.state.arr[arrIndex].person)
        break
      case 3:
        this.setState({
          prodCode: "sku_FkjyqVPNE4TaIC",
        })
        console.log("Booking for", this.state.arr[arrIndex].person)
        break

      default:
        break
    }
  }

  dateChecker(date, time) {
    const startInstanceString = date + "," + time

    const startInstance = new Date(startInstanceString).getTime()

    const currentTime = new Date().getTime()

    if (startInstance < currentTime) {
      return true
    }

    return false
  }

  viewMore() {
    console.log("View more button")
  }

  displayOn = number => {
    const modalIndex = number - 1

    switch (modalIndex) {
      case 0:
        this.setState(
          {
            display0: true,
          },
          function() {
            console.log("Display display0 set to", this.state.display0)
          }
        )
        break
      case 1:
        this.setState(
          {
            display1: true,
          },
          function() {
            console.log("Display display1 set to", this.state.display1)
          }
        )
        break
      case 2:
        this.setState(
          {
            display2: true,
          },
          function() {
            console.log("Display display2 set to", this.state.display2)
          }
        )
        break

      default:
        break
    }
  }

  render() {
    const listEvents = this.state.arr.map(event => (
      <p key={event.id} style={eventStyles}>
        <span style={{ marginLeft: `0.5rem` }}>
          {event.startDate} - {event.endDate}
        </span>{" "}
        <span style={{ marginLeft: `0.5rem` }}>
          {event.bookingStartTime} - {event.bookingEndTime}
        </span>{" "}
        <span
          style={{
            marginLeft: `0.5rem`,
            color: `grey`,
            fontSize: `1rem`,
            fontWeight: `bold`,
          }}
        >
          {event.topic}
        </span>{" "}
        <span style={{ marginLeft: `0.5rem`, color: `#504e4e` }}>
          {event.person}
        </span>
        <span
          style={{ margin: `0.5rem auto` }}
          onClick={() => this.displayOn(event.id)}
        >
          <img src={ViewMore} alt="View More" style={imgButtonStyles} />{" "}
          <span style={{ marginLeft: `0.3rem` }}>View More</span>
        </span>
        {!this.dateChecker(event.startDate, event.bookingStartTime) && (
          <Link
            to="/checkout"
            state={{ productCode: event.prodCode }}
            style={{ display: `grid` }}
          >
            <Button
              style={{
                marginBottom: `0.0rem`,
                backgroundColor: `#FF5722`,
                fontWeight: `600`,
                height: `2.4rem`,
                margin: `0.5rem auto`,
                width: `85%`,
                zIndex: `1`,
                border: `none`,
              }}
              onClick={() => {
                this.logMessages(event.id)
              }}
              id={event.topic}
            >
              BOOK NOW
            </Button>
          </Link>
        )}
      </p>
    ))

    return (
      <Layout>
        <SEO title="Events and Courses" />
        <div className="mainEvents">
          <img src={sideImageL} alt="sidebanner" className="sideImageLEvents" />
          <div style={divStyles} className="divStyles__events">
            <h2
              style={{
                textAlign: `center`,
                fontWeight: `600`,
              }}
              className="events_page_headline"
            >
              Events And Courses
            </h2>
            <span
              style={{
                textAlign: `justify`,
                fontWeight: `unset`,
                fontStyle: `oblique`,
              }}
              className="events_page_firstpara"
            >
              <p>
                A lot of people feel going to a workshop or a yoga retreat has
                been a major turning point in their life. These are truly
                transformative experiences and it is our belief that anyone who
                enjoys a lifestyle of health and wellness can greatly benefit
                from these experiences.
              </p>
              <p>
                Create an intervention to see the world or yourself in a new
                way. A workshop in a new place can be a great conduit to take
                you away from the everyday life ongoings. Experiencing the
                unknown is an accelerated way to grow and learn.
              </p>
            </span>
            <div>{listEvents}</div>
            {this.state.display0 && (
              <div id="overlay">
                <div className="overlayMain">
                  <img src={A} alt="Portfolio" className="overlayImg" />
                  <span className="closeButton">
                    <img
                      src={CloseButtonImg}
                      alt="Close Button"
                      onClick={() => {
                        this.setState({ display0: false }, function() {
                          console.log("display", this.state)
                        })
                      }}
                    />
                  </span>

                  <span className="overlayDescription">
                    <h4 style={{ margin: `0.6rem 0` }}>
                      Yoga With Annie ~ Hatha Flow Description
                    </h4>
                    <p className="overlayPara">
                      Hatha Yoga is an "asana" or posture driven yoga practice.
                      It focuses on the development of the physical body as a
                      whole, so that the mind can process or handle mental
                      transformations. In essence it is the mind and body
                      connection. Build a solid foundation in the basic poses.
                      Level 1 classes are appropriate for those who are newer to
                      yoga as well as continuing students who are looking to
                      refine their experience of the core poses. Classes
                      emphasize standing poses, basic sun salutations, gentle
                      backbends and twists, and preparation for inversions.
                      Classes emphasize developing strength, flexibility, and
                      ease in the body in order to quiet the mind.{" "}
                    </p>
                    <p className="overlayPara">
                      {" "}
                      Once the body and mind has created some space "asana's"
                      are held for longer moments and each posture is done once
                      on each side. It is a beautiful practice to learn general
                      and energetic alignment, while challenging your body to
                      find its edge.
                    </p>
                  </span>
                </div>
              </div>
            )}
            {this.state.display1 && (
              <div id="overlay">
                <div className="overlayMain">
                  <img src={B} alt="Portfolio" className="overlayImg" />
                  <span className="closeButton">
                    <img
                      src={CloseButtonImg}
                      alt="Close Button"
                      onClick={() => {
                        this.setState({ display1: false }, function() {
                          console.log("display state", this.state)
                        })
                      }}
                    />
                  </span>

                  <span className="overlayDescription">
                    <h3 style={{ margin: `0.6rem 0` }}>
                      Learn to Meditate with Lorea & Pascal
                    </h3>
                    <p className="overlayPara">
                      Learn and practice the secret to a healthy, balanced life.
                      Build rock-solid foundations upon which you can rest the
                      rest of your life's demands. Left unchecked errant
                      thoughts noisily bounce around in our heads all day. This
                      course is designed to dampen and quiet the mind; Allowing
                      us to become more aware of thoughts, ideas and beliefs and
                      consciously allow rental space in our mind-space, but only
                      if we choose to. Contrary to popular demands on most of
                      us, our brains actually work best when focussed on a
                      single task, not multi-tasking and distracted.{" "}
                    </p>
                    <p className="overlayPara">
                      {" "}
                      Lorea & Pascal’s classes focus on balancing strength and
                      flexibility through a variety of vinyasa sequences and
                      asana. They incorporate living essential oils into her
                      classes through diffusing and shavasana massage to enhance
                      her students’ yoga experience. You can also expect
                      Ashtanga influences, meditations and breath work during
                      class.The brain and body works best moving together. Some
                      classes in the course follow the recipe of 30 minutes of
                      gentle yoga, 20 mins of guided meditation followed by 10
                      minutes of Shavasana. Its our favorite combination of
                      relaxation! Your brain, heart soul and body will thank
                      you!
                    </p>
                  </span>
                </div>
              </div>
            )}
            {this.state.display2 && (
              <div id="overlay">
                <div className="overlayMain">
                  <img src={CC} alt="Portfolio" className="overlayImg" />
                  <span className="closeButton">
                    <img
                      src={CloseButtonImg}
                      alt="Close Button"
                      onClick={() => {
                        this.setState({ display2: false }, function() {
                          console.log("display state", this.state)
                        })
                      }}
                    />
                  </span>

                  <span className="overlayDescription">
                    <h3 style={{ margin: `0.6rem 0` }}>
                      Advanced Yoga Flow Series - Hatha And Ashtanga
                    </h3>
                    <p className="overlayPara">
                      Our advanced yoga flow course will provide the opportunity
                      to explore and practice a wide variety of arm balances in
                      a way that will dissolve fear and encourage lightness and
                      playfulness background. Sessions will focus on
                      synchronizing the breathe ith a slowing sequence of
                      postures and use transitional vinyasa sequences between
                      sustained postures also. The flow will include components
                      derived from ashtanga yoga such as sun salutations,
                      bandhas (constrictions of specific muscles and organs to
                      contain energy) and drishti (gaze), despite no pre-set
                      sequences.
                    </p>
                    <p className="overlayPara">
                      {" "}
                      Emilia then sprinkles in components of other movement
                      styles, yoga practices to create a truly experiential
                      experience. She opens the door to give you permission to
                      just be - and often states that there is truly no one
                      perfect, no one way. Emilia uses the foundations of Hatha
                      and Ashtanga in creating the her choreographed sequences.
                      The course will suit students of all levels, looking to
                      build a strong base and practice. The only prerequisite is
                      curiosity! :)
                    </p>
                  </span>
                </div>
              </div>
            )}
          </div>
          <img src={sideImageR} alt="sidebanner" className="sideImageREvents" />
        </div>
      </Layout>
    )
  }
}

export default Events
